<template>
  <!--内容-->
  <div class="page-filtrate">
    <div class="gk-container">
      <!--筛选条件-->
      <div class="filter-container">
        <div class="filter-condition">
          <!--  条件头    -->
          <div class="title">
            <div class="left">
              <div class="tit">
                <a href="/">首页</a>&nbsp;&gt;&nbsp;<span>落地案例</span>
              </div>
              <div class="condition">
                <div class="item" v-for="(m,index) in filter_data" v-bind:key="index">
                  <span>{{ m.name }}</span>
                  <img src="static/iconImg/icon-close.png" alt="" @click="del_filter(index,m)">
                </div>
              </div>
              <div v-if="this.filter_data.length>0" class="op" @click="clear_filter()">清除全部</div>
            </div>
            <div class="right" style="display:none;"><span>收起筛选</span><img src="static/iconImg/icon-drop-down.png" alt=""></div>
          </div>
          <!--  条件筛选  -->
          <div class="filter-list">
            <div class="item">
              <div class="cate-name">行业类型:</div>
              <div class="list">
                <span v-for="(m,index) in business_data" v-bind:key="index" :class="{active:business_id===m.id}"  @click="businessChange(m)">
                  {{ m.name }}
                </span>
              </div>
            </div>
            <div class="item">
              <div class="cate-name">品牌:</div>
              <div class="list">
                <span v-for="(m,index) in brand_data" v-bind:key="index" :class="{active:brand_id===m.id}" @click="brandChange(m)">
                  {{ m.name }}
                </span>
              </div>
            </div>
<!--            <div class="item">
              <div class="cate-name">其他筛选：</div>
              <div class="drop-down-list">
                <div class="list-mod">
                  <span>节日节点</span>
                  <img src="static/iconImg/icon-drop-down.png" alt="">
                </div>
                <div class="list-mod">
                  <span>风格调性</span>
                  <img src="static/iconImg/icon-drop-down.png" alt="">
                </div>
              </div>
            </div>-->
          </div>
          <!-- 其他条件 -->
          <div class="other-condition">
            <div class="left">
              <a href="javascript:;" class="active">最新上传</a>
<!--              <a href="javascript:;">最热下载</a>-->
            </div>
<!--            <div class="right">
              <div class="item">
                <img src="static/iconImg/icon-radio.png" alt=""><span>VIP免费下</span>
              </div>
              <div class="item">
                <img src="static/iconImg/icon-radio-selected.png" alt=""><span>限时折扣</span>
              </div>
              <div class="item">
                <img src="static/iconImg/icon-radio.png" alt=""><span>包含场地</span>
              </div>
              <div class="item">
                <img src="static/iconImg/icon-radio.png" alt=""><span>包含设计</span>
              </div>
              <div class="item">
                <img src="static/iconImg/icon-radio.png" alt=""><span>高分作品</span>
              </div>
              <div class="item">
                <img src="static/iconImg/icon-radio.png" alt=""><span>知名品牌</span>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <!--  筛选结果列表  -->
      <div class="filter-result-list scheme-list-mod" v-loading="loading" element-loading-background="rgba(245,245,245,1)">
        <ul>
          <li v-for="(m,index) in data_list" v-bind:key="index">
            <div class="pic">
              <router-link :to="'/detail/'+m.id">
                <img :src="m.img" :alt="m.title">
              </router-link>
            </div>
            <h3>
              <router-link :to="'/detail/'+m.id">{{ m.title }}</router-link>
            </h3>
            <div class="desc">{{ m.description }}</div>
            <div class="info">
              <div class="author"><img :src="m.user.avatar" alt=""><span>{{ m.user.nickname }}</span></div>
              <div class="viewer"><img src="static/iconImg/icon-view.png" alt=""><span>{{ m.view }}</span></div>
            </div>
          </li>
        </ul>
      </div>
      <!-- 页码-->
      <div class="pagination">
        <ul>
          <li @click="prevPage" :class="{hide:current_page === 1}">
            <a class="arrow" href="javascript:;">
              <img src="static/iconImg/icon-left-arrow.png" alt="">
            </a>
          </li>
          <li v-for="page in last_page" :key="page" @click="pagination(page)">
            <a href="javascript:;"  :class="{active: current_page === page}">{{page}}</a>
          </li>
          <li @click="nextPage" :class="{hide:current_page === last_page || last_page===0}">
            <a class="arrow" href="javascript:;">
              <img src="static/iconImg/icon-right-arrow.png" alt="">
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import article from "@/api/article";

export default {
  name: "list",
  components: {},
  computed: {
    // 使用计算属性来监听 Vuex 变量
    keyword() {
      return this.$store.state.keyword;
    }
  },
  data() {
    return {
      ident:'case',
      per_page: 0,
      total: 0,
      current_page: 1,
      last_page: 0,
      data_list: [],
      codeUrl: '',
      cid: 0,
      business_id: 0,
      business_data: [],
      brand_id: 0,
      brand_data: [],
      filter_data: [],
      loading:false
    }
  },
  created() {
    //this.ident = this.$route.params.ident;
    this.getList();
    this.getBusinessData();
    this.getBrandData();
  },
  unmounted() {
    this.$store.commit("updateKeyword","");
  },
  watch: {
    // 当 keyword 变化时，这个函数就会被调用
    keyword(newValue, oldValue) {
      console.log(`keyword changed from ${oldValue} to ${newValue}`);
      this.getList();
    }
  },
  methods: {
    //单个 删除
    del_filter(index, item) {
      this.filter_data.splice(index, 1);
      if (item.pident === 'business') {
        this.business_id = 0;
      }
      if (item.pident === 'brand') {
        this.brand_id = 0;
      }
      this.getList();
    },
    //全部清理
    clear_filter() {
      this.filter_data.splice(0, this.filter_data.length);
      this.business_id = 0;
      this.brand_id = 0;
      this.getList();
    },
    //切换 类型
    typeChange(cid) {
      this.cid = cid;
      this.getList();
    },
    //切换 行业
    businessChange(item) {
      this.business_id = item.id;
      //清理同类型筛选
      const index = this.filter_data.findIndex(item => item.pident === 'business');
      if (index > -1) {
        this.filter_data.splice(index, 1);
      }

      this.filter_data.push(item);
      this.getList();
    },
    //切换 品牌
    brandChange(item) {
      this.brand_id = item.id;

      //清理同类型筛选
      const index = this.filter_data.findIndex(item => item.pident === 'brand');
      if (index > -1) {
        this.filter_data.splice(index, 1);
      }

      this.filter_data.push(item);
      this.getList();
    },
    pagination(page) {
      if (this.current_page == page) {
        return false;
      } else {
        this.current_page = page;
        this.getList();
      }
    },
    //上一页
    prevPage() {
      if (this.current_page <= 1) {
        return false;
      } else {
        this.current_page = this.current_page-1;
        this.getList();
      }
    },
    //下一页
    nextPage() {
      if (this.current_page >= this.last_page) {
        return false;
      } else {
        this.current_page = this.current_page+1;
        this.getList();
      }
    },
    //行业 筛选
    getBusinessData() {
      article.getCategoryNameList({
        ident: 'business'
      }).then(res => {
        this.business_data = res.data;
      })
    },
    //品牌 筛选
    getBrandData() {
      article.getCategoryNameList({
        ident: 'brand'
      }).then(res => {
        this.brand_data = res.data;
      })
    },
    //调取 列表数据
    getList() {
      this.loading = true
      article.getList({
        keyword: this.keyword,
        type: this.cid,
        ident: this.ident,
        business: this.business_id,
        brand: this.brand_id,
        page: this.current_page
      }).then(res => {
        if (res) {
          this.data_list = res.data.data;
          this.per_page = res.data.per_page;
          this.total = res.data.total;
          this.current_page = res.data.current_page;
          this.last_page = res.data.last_page;
          this.loading = false
        } else {
          console.log(res);
        }
        window.scrollTo(0, 0);
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.hide {
  display: none;
}
</style>